@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-BlackItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-BlackItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-BlackItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-BlackItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-BlackItalic.svg#../../shared/assets/fonts/Rota/Rota-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Black.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Black.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Black.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Black.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Black.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Black.svg#../../shared/assets/fonts/Rota/Rota-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Bold.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Bold.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Bold.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Bold.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Bold.svg#../../shared/assets/fonts/Rota/Rota-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-BoldItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-BoldItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-BoldItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-BoldItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-BoldItalic.svg#../../shared/assets/fonts/Rota/Rota-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlack.svg#../../shared/assets/fonts/Rota/Rota-ExtraBlack') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ThinItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ThinItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ThinItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ThinItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ThinItalic.svg#../../shared/assets/fonts/Rota/Rota-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBold.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBold.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBold.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBold.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBold.svg#../../shared/assets/fonts/Rota/Rota-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-MediumItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-MediumItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-MediumItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-MediumItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-MediumItalic.svg#../../shared/assets/fonts/Rota/Rota-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic.svg#../../shared/assets/fonts/Rota/Rota-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Regular.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Regular.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Regular.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Regular.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Regular.svg#../../shared/assets/fonts/Rota/Rota-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Italic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Italic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Italic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Italic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Italic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Italic.svg#../../shared/assets/fonts/Rota/Rota-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBoldItalic.svg#../../shared/assets/fonts/Rota/Rota-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-SemiBold.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBold.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBold.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBold.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-SemiBold.svg#../../shared/assets/fonts/Rota/Rota-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraLight.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLight.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLight.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLight.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLight.svg#../../shared/assets/fonts/Rota/Rota-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraLightItalic.svg#../../shared/assets/fonts/Rota/Rota-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Thin.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Thin.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Thin.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Thin.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Thin.svg#../../shared/assets/fonts/Rota/Rota-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic.svg#../../shared/assets/fonts/Rota/Rota-ExtraBlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Medium.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Medium.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Medium.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Medium.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Medium.svg#../../shared/assets/fonts/Rota/Rota-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-LightItalic.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-LightItalic.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-LightItalic.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-LightItalic.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-LightItalic.svg#../../shared/assets/fonts/Rota/Rota-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rota';
    src: url('../../shared/assets/fonts/Rota/Rota-Light.eot');
    src: url('../../shared/assets/fonts/Rota/Rota-Light.eot?#iefix') format('embedded-opentype'),
    url('../../shared/assets/fonts/Rota/Rota-Light.woff2') format('woff2'),
    url('../../shared/assets/fonts/Rota/Rota-Light.woff') format('woff'),
    url('../../shared/assets/fonts/Rota/Rota-Light.ttf') format('truetype'),
    url('../../shared/assets/fonts/Rota/Rota-Light.svg#../../shared/assets/fonts/Rota/Rota-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


html, body, #root {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #BFC4C5;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #001315;
    border-radius: 5px;
}

.container {
    max-width: 100% !important;
}

.container.small-container {
    max-width: 845px !important;
}

.flex-direction-column {
    flex-direction: column;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #80898A !important;
    opacity: 1 !important; /* Firefox */
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #80898A !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #80898A !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #80898A !important;
}

.border-bottom-none {
    border-bottom: 0 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.pointer-events-none {
    pointer-events: none !important;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

a.cursor-pointer {
    color: inherit !important;
    text-decoration: none;
}

input:-webkit-autofill {
    background-color: transparent !important;
}

form {
    width: 100%;
}

.form-input,
.form-textarea,
.form-select-label {
    width: 100%;
    position: relative;
}

input.form-control:disabled, input.form-control[readonly] {
    background-color: #E5E7E8 !important;
    color: #80898A !important;
    opacity: 1;
}

.form-select-label select.custom-select.is-invalid:focus,
.form-select-label select.custom-select.is-invalid {
    border: 2px solid #EB3300 !important;
}

.form-textarea textarea.form-control.is-invalid,
.form-input input.form-control.is-invalid {
    border: 2px solid #EB3300 !important;
    background-image: none !important;
}

@supports (not (-ms-ime-align:auto)) {
    input.form-control + label {
        font-family: Rota;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #80898A;
        width: auto;
        padding-left: 20px;
        position: absolute;
        top: 18px;
        transition: all 0.2s ease-out;
    }

    input.form-control {
        font-family: Rota;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #001315;
        background: #E5E7E8;
        border: 2px solid #E5E7E8;
        border-radius: 12px;
        padding: 19px 20px;
        margin-bottom: 10px;
        height: 56px !important;
        transition: all 0.2s ease-out;
    }

    input.form-control:focus + label {
        font-size: 14px;
        line-height: 17px;
        color: #FF6A39 !important;
        top: 10px;
    }

    .doctor-app input.form-control:focus + label {
        color: #00CFB4 !important;
    }

    input.form-control:not([value=""]) {
        padding: 28px 18px 10px 18px;
    }

    input.form-control:not(:placeholder-shown) + label {
        font-size: 14px;
        line-height: 17px;
        color: #80898A;
        top: 10px;
    }

    input.form-control:focus {
        padding: 28px 18px 10px 18px;
        border: 2px solid #E5E7E8 !important;
        background: #FFFFFF;
        outline: none !important;
        box-shadow: none !important;
    }

    .form-select-label select.custom-select:focus {
        border: 2px solid #E5E7E8 !important;
        background-color: #FFFFFF;
        outline: none !important;
        box-shadow: none !important;
    }

    .form-select-label select.custom-select:valid {
        padding: 22px 18px 4px 18px;
        position: relative;
        z-index: 5;
    }

    .form-select-label select.custom-select + label {
        font-size: 0;
    }

    .form-select-label select.custom-select:valid + label {
        font-family: Rota;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #80898A;
        width: auto;
        padding-left: 20px;
        position: absolute;
        z-index: 5;
        left: 0;
        top: 10px;
    }

    textarea.form-control + label {
        font-family: Rota;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #80898A;
        width: auto;
        padding-left: 20px;
        position: absolute;
        top: 18px;
        transition: all 0.2s ease-out;
    }

    textarea.form-control {
        font-family: Rota;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #001315;
        background: #E5E7E8;
        border: 2px solid #E5E7E8;
        border-radius: 12px;
        padding: 19px 20px;
        margin-bottom: 10px;
        transition: all 0.2s ease-out;
    }

    textarea.form-control:focus + label {
        font-size: 14px;
        line-height: 17px;
        color: #FF6A39 !important;
        top: 10px;
    }

    .doctor-app textarea.form-control:focus + label {
        color: #00CFB4 !important;
    }

    textarea.form-control:not([value=""]) {
        padding: 28px 18px 10px 18px;
    }

    textarea.form-control:not(:placeholder-shown) + label {
        font-size: 14px;
        line-height: 17px;
        color: #80898A;
        top: 10px;
    }

    textarea.form-control:focus {
        padding: 28px 18px 10px 18px;
        border: 2px solid #E5E7E8 !important;
        background: #FFFFFF;
        outline: none !important;
        box-shadow: none !important;
    }
}

select.custom-select:invalid {
    color: #80898A;
}

select.custom-select option {
    color: #001315;
}

select.custom-select {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background-color: #E5E7E8;
    background-image: url('../assets/icons/select-arrow-custom-icon.svg');
    background-repeat: no-repeat;
    background-position: right 17px center;
    background-size: 24px 24px;
    border-radius: 12px;
    padding: 16px 50px 16px 20px;
    margin-bottom: 10px;
    height: 56px !important;
    width: 100%;
    border: 2px solid #E5E7E8 !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    appearance: none;
}

svg.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
}

svg.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.button-sticky-bottom {
    background: #F2F3F3;
    padding-top: 20px;
    padding-bottom: 50px;
    border-top: 1px solid #E5E7E8;
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 100;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}

.button-sticky-bottom button {
    max-width: 400px;
}

hr {
    border-top-color: #E5E7E8 !important;
    opacity: 1 !important;
    width: 100% !important;
    margin-top: 0 !important;
}

.rowp5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
}

.rowp10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.colp5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.colp10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.orange-text {
    color: #FF6A39;
}

.green-text {
    color: #00CFB4;
}

.cancel-button {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cancel-button button {
    padding: 0;
    background: none;
    border: 0;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
}

.blue-button {
    background-color: #2196f3;
    padding: 14px 20px;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #2196f3;
    min-height: 56px;
    width: 100%;
}

/*.orange-button:hover {*/
/*    background-color: #FFFFFF;*/
/*    color: #FF6A39;*/
/*    border: 2px solid #FF6A39;*/
/*    text-decoration: none;*/
/*}*/

.green-button {
    background-color: #00CFB4;
    padding: 14px 20px;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    border: 2px solid #00CFB4;
    min-height: 56px;
    width: 100%;
}

/*.green-button:hover {*/
/*    background-color: #FFFFFF;*/
/*    color: #00CFB4;*/
/*    border: 2px solid #00CFB4;*/
/*    text-decoration: none;*/
/*}*/

.white-button {
    background-color: #FFFFFF;
    padding: 14px 20px;
    border-radius: 12px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #001315;
    border: 2px solid #FFFFFF;
    min-height: 56px;
    width: 100%;
}

/*.white-button:hover {*/
/*    background-color: #001315;*/
/*    color: #FFF;*/
/*    border: 2px solid #001315;*/
/*    text-decoration: none;*/
/*}*/

.bm-burger-button.mobile-menu {
    display: none;
}

.modal .modal-dialog {
    max-width: 400px;
}

.modal .modal-content {
    background: #F2F3F3;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    border: 0;
}

.modal .modal-header {
    padding: 18px 20px;
    border-bottom: 1px solid #E5E7E8;
}

.modal .modal-header.subheader {
    padding: 15px 20px;
}

.modal .modal-header.subheader .white-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal .modal-header.subheader .white-button svg {
    margin-right: 16px;
}

.modal .modal-header .modal-title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #001315;
}

.modal .modal-header .btn-close {
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    opacity: 1;
    border-radius: 0;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
}

.modal .modal-header .btn-close:after {
    content: attr(aria-label);
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #FF6A39;
    display: inline-flex;
}

.modal.medic-modal .modal-header .btn-close:after {
    color: #00CFB4;
}

.modal .modal-body {
    padding: 20px;
}

.modal .modal-footer {
    border-top: 1px solid #E5E7E8;
    padding: 20px;
}

.modal .modal-footer > * {
    margin: 0;
}

header.menu {
    height: fit-content;
    background-color: #FFFFFF;
}

header.menu.primary-pages > .container {
    max-width: 1008px !important;
}

/*header landing + login/register */
header .view.login-register-menu {
    display: flex;
    align-items: center;
    padding: 28px 0;
}

header .view.landing-menu {
    display: flex;
    align-items: center;
    padding: 15px 0;
}

header .login-register-menu .logo,
header .landing-menu .logo {
    display: inline-flex;
    margin-right: auto;
}

header .login-register-menu .menu,
header .landing-menu .menu {
    display: inline-flex;
    margin-left: auto;
}

header .login-register-menu .menu ul,
header .landing-menu .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

header .landing-menu .menu ul li a {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #BFC4C5;
    text-decoration: none;
}

header.menu-medic .landing-menu .menu ul li.my-account a,
header.menu-patient .landing-menu .menu ul li.my-account a {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: #001315;
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 3px 3px 3px 10px;
}

header.menu-medic .landing-menu .menu ul li.my-account a .user-image,
header.menu-patient .landing-menu .menu ul li.my-account a .user-image {
    margin-right: 6px !important;
}

header.menu-patient .landing-menu .menu ul li.my-account a .bg-arrow,
header.menu-medic .landing-menu .menu ul li.my-account a .bg-arrow {
    border-radius: 8px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
}

header .landing-menu .menu ul li.my-account a .bg-arrow.green {
    background: #00CFB4;
}

header .landing-menu .menu ul li.my-account a .bg-arrow.orange {
    background: #FF6A39;
}

header.menu-medic .landing-menu .menu ul li.auth a {
    background: #00CFB4;
    border-radius: 12px;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 14px;
}

header.menu-patient .landing-menu .menu ul li.auth a {
    background: #FF6A39;
    border-radius: 12px;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 14px;
}

header .landing-menu .menu ul li {
    display: inline-flex;
    margin: 0 15px;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #BFC4C5;
}

header .landing-menu .menu ul li.active {
    color: #001315;
}

header .login-register-menu .menu ul li.login {
    display: inline-flex;
    margin-right: 20px;
}

header .login-register-menu .menu ul li.login a {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #BFC4C5;
    text-decoration: none !important;
}

.doctor-app header .login-register-menu.login-register-menu .menu ul li.register {
    background: #00CFB4;
}

.patient-app header .login-register-menu.login-register-menu .menu ul li.register {
    background: #FF6A39;
}

header .login-register-menu .menu ul li.register {
    display: inline-flex;
    border-radius: 12px;
    padding: 8px 18px;
}

header .login-register-menu .menu ul li.register a {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none !important;
}

/*header landing +login/register end*/
header .is-logged-menu {
    padding: 0 !important;
}

header .is-logged-menu .menu {
    padding-top: 30px;
    padding-bottom: 12px;
}

header .is-logged-menu .menu .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

header .is-logged-menu .menu nav {
    display: flex;
}

header .is-logged-menu .menu nav a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    margin-right: 30px;
}

header .is-logged-menu .menu nav a span {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #BFC4C5;
    position: relative;
    bottom: -2px;
}

header .is-logged-menu .menu nav a.notifications span {
    display: none;
}

header .is-logged-menu .menu nav a.active span {
    color: #001315;
}

header .is-logged-menu .menu nav a.active svg path {
    fill: rgba(0, 19, 21, 1) !important;
}

header .is-logged-menu .menu nav a svg {
    margin-right: 5px;
}

header .is-logged-menu .menu nav a.notifications {
    margin-left: auto;
    margin-right: 0;
}

header .is-logged-menu .menu nav a.notifications svg {
    margin-right: 0;
}

header .is-logged-menu .menu nav a.notifications .count,
.bm-menu nav li > a.notifications .count {
    background: #FF6A39;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    position: relative;
}

.bm-menu nav li > a.notifications .count {
    margin-left: 5px;
}

header .is-logged-menu .menu nav a.notifications .count.plus,
.bm-menu nav li > a.notifications .count.plus {
    width: 26px;
}

header .is-logged-menu .menu nav a.notifications .count .number,
.bm-menu nav li > a.notifications .count .number {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

header.menu-medic .is-logged-menu .menu nav a.notifications .count {
    background: #00CFB4;
}

/*-------SPACE--------*/


/*-------SPACE--------*/

.bm-overlay {
    background: rgba(0, 19, 21, 0.2) !important;
    top: 67px !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 22px;
    height: 16px;
    right: 25px;
    top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #001315;
    border-radius: 10px;
    height: 2px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    width: 22px !important;
    height: 22px !important;
    right: 25px !important;
    top: -47px !important;
}

.bm-cross-button button {
    background-color: #FFF !important;
}

.bm-cross-button > span {
    z-index: 10;
    top: 0 !important;
    right: 12px !important;
}

.bm-cross-button .bm-cross {
    transform: rotate(90deg) !important;
    background: #001315 !important;
    width: 2px !important;
    height: 22px !important;
    border-radius: 10px;
}

.bm-cross-button .bm-cross:last-child {
    display: none !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: auto !important;
    width: 100% !important;
    top: 67px !important;
}

/* General sidebar styles */
.bm-menu {
    background: #FFF;
    padding: 0 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.bm-menu nav {
    padding: 0;
}

.bm-menu nav ul {
    padding: 0;
    margin: 0;
    list-style: none !important;
}

.bm-menu nav li > a,
.bm-menu nav li > div {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bm-menu nav li > a > div {
    display: flex;
    align-items: center;
}

.bm-menu nav ul li {
    border-bottom: 1px solid #F2F3F3;
}

.bm-menu nav a.active {
    color: #001315;
}

.bm-menu nav a svg {
    margin-right: 5px;
}

.bm-menu nav a.active svg path {
    fill: rgba(0, 19, 21, 1) !important;
}

.bm-menu nav ul li.active {
    color: #FF6A39;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.custom-switch .custom-control-input ~ .custom-control-label::before {
    width: 34px !important;
    height: 14px !important;
    border: 0 !important;
    background: rgba(0, 0, 0, 0.3) !important;
    border-radius: 7px !important;
    outline: none !important;
    box-shadow: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    width: 34px !important;
    height: 14px !important;
    border: 0 !important;
    background: rgba(0, 203, 178, 0.38) !important;
    border-radius: 7px !important;
    outline: none !important;
    box-shadow: none !important;
}

.custom-switch .custom-control-label::after {
    top: 1px;
    left: -40px !important;
    width: 20px !important;
    height: 20px !important;
    background-color: #00CFB4 !important;
    border-radius: 100% !important;
}

.custom-switch .custom-control-input ~ .custom-control-label::after {
    left: -37px !important;
    background-color: #E5E7E8 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 100% !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    left: -34px !important;
    background-color: #00CFB4 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 100% !important;
}

.dropdown-wrapper .dropdown-toggle::after {
    display: none;
}

.dropdown-wrapper .btn-success,
.show > .btn-success.dropdown-toggle,
.btn-success:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
}

.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0);
}

.modal-backdrop {
    background: #001315 !important;
    z-index: 98 !important;
}

.custom-checkbox,
.custom-radio {
    position: relative;
}

.custom-checkbox.documents-checkbox {
    margin-top: 14px;
    margin-bottom: 10px;
}

.custom-checkbox.with-description {
    border-bottom: 1px solid #F2F3F3;
    margin-top: 14px;
    padding-bottom: 12px;
}

.custom-checkbox.with-description:last-child {
    padding-bottom: 0;
    margin-bottom: 14px;
    border: 0;
}

.custom-checkbox .custom-control-input,
.custom-radio .custom-control-input {
    width: 24px !important;
    height: 24px !important;
    display: none;
}

.custom-checkbox label {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    padding-left: 38px;
    cursor: pointer;
}

.custom-checkbox.select-all.active-label-orange .custom-control-label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #BFC4C5;
    min-width: 70px;
}

.custom-checkbox.right-checkbox label {
    padding-left: 0;
    align-items: center;
    display: flex;
}

.custom-checkbox.right-checkbox.small-avatar label {
    align-items: flex-start;
    position: relative;
}

.custom-radio label {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-checkbox label a {
    font-weight: bold !important;
    color: #001315 !important;
}

.custom-checkbox .custom-control-label::before,
.custom-radio .custom-control-label::before {
    content: '';
    width: 24px;
    height: 24px;
    border: 0;
    border-radius: 0;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 5;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    outline: none !important;
    box-shadow: none !important;
}

.custom-checkbox.right-checkbox .custom-control-label::before,
.custom-radio.right-radio .custom-control-label::before {
    top: auto;
    bottom: auto;
    right: 20px;
    left: auto;
}

.custom-checkbox.align-center-checkbox.left-justify-content .custom-control-label::before,
.custom-radio.align-center-radio.left-justify-content .custom-control-label::before {
    top: 0;
    bottom: 0;
    right: auto;
    left: 0;
    margin: auto;
}

.custom-checkbox.align-center-checkbox .custom-control-label::before,
.custom-radio.align-center-radio .custom-control-label::before {
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
    margin: auto;
}

.custom-checkbox.active-label-orange .custom-control-label .title,
.custom-checkbox.active-label-green .custom-control-label .title {
    font-weight: bold;
}

.custom-checkbox .custom-control-label .details,
.custom-radio .custom-control-label .details {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

.custom-checkbox .custom-control-label .details2 {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
}

.custom-checkbox .custom-control-label + .details-box {
    padding-left: 40px;
}

.custom-checkbox .custom-control-label + .details-box .item .title {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #80898A;
    border-top: 1px solid #F2F3F3;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-top: 15px;
}

.custom-checkbox .custom-control-label + .details-box .item .description {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.custom-checkbox .custom-control-label + .details-box .item .document a {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    text-decoration: none !important;
}

.custom-checkbox .custom-control-label + .details-box .item .document a .info {
    display: inline-flex;
    flex-direction: column;
}

.custom-checkbox .custom-control-label + .details-box .item .document a .info .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315 !important;
    margin-bottom: 2px;
}

.custom-checkbox .custom-control-label + .details-box .item .document a .info .data {
    font-family: Rota;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A !important;
}

.custom-checkbox .custom-control-label + .details-box .item .document a .custom-icon.document {
    margin-right: 16px;
    display: inline-flex;
}

.custom-checkbox .custom-control-label + .details-box .item .document a .custom-icon.arrow {
    position: absolute;
    right: -8px;
    top: auto;
    bottom: auto;
}

.custom-radio .custom-control-label img {
    margin-right: 20px;
}

.custom-checkbox .custom-control-label .avatar,
.custom-radio .custom-control-label .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox.small-avatar .custom-control-label .avatar {
    width: 24px;
    height: 24px;
}

.custom-checkbox .custom-control-label svg,
.custom-checkbox .custom-control-label .avatar,
.custom-checkbox .custom-control-label img {
    margin-right: 16px;
}

.custom-checkbox.select-all.active-label-orange .custom-control-input:checked ~ .custom-control-label,
.custom-checkbox.active-label-orange .custom-control-input:checked ~ .custom-control-label .title,
.custom-radio.active-label-orange .custom-control-input:checked ~ .custom-control-label .title {
    color: #FF6A39;
}

.custom-checkbox.select-all.active-label-green .custom-control-input:checked ~ .custom-control-label,
.custom-checkbox.active-label-green .custom-control-input:checked ~ .custom-control-label .title,
.custom-radio.active-label-green .custom-control-input:checked ~ .custom-control-label,
.custom-radio.active-label-green .custom-control-input:checked ~ .custom-control-label .title {
    color: #00CFB4;
}

.custom-checkbox .custom-control-label::before {
    background-image: url("../assets/icons/unselect-checkbox-icon.svg");
}

.custom-radio .custom-control-label::before {
    background-image: url("../assets/icons/unselect-radio-icon.svg");
}

.custom-checkbox.green-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    content: '';
    background-image: url("../assets/icons/select-green-checkbox-icon.svg");
}

.custom-radio.green-radio .custom-control-input:checked ~ .custom-control-label::before {
    content: '';
    background-image: url("../assets/icons/select-green-radio-icon.svg");
}

.custom-checkbox.orange-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    content: '';
    background-image: url("../assets/icons/select-orange-checkbox-icon.svg");
}

.custom-radio.orange-radio .custom-control-input:checked ~ .custom-control-label::before {
    content: '';
    background-image: url("../assets/icons/select-orange-radio-icon.svg");
}

.custom-checkbox .custom-control-label::after,
.custom-radio .custom-control-label::after {
    content: '';
    display: none !important;
}

.custom-switch.form-switch {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-left: 0;
    margin: 0;
}

.custom-switch input.form-check-input {
    width: 52px;
    height: 32px;
    margin-left: 20px;
    margin-top: 0;
    border: 0;
    border-radius: 18px;
    background-size: 28px 28px;
    background-color: #BFC4C5;
    background-image: url("../../shared/assets/icons/switch-inactive-icon.svg") !important;
    background-position: left 2px center;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.custom-switch input.form-check-input:checked {
    background-color: #FF6A39;
    background-image: url("../../shared/assets/icons/switch-active-orange-icon.svg") !important;
    background-position: right 2px center;
}

.custom-switch.green-switch input.form-check-input:checked {
    background-color: #00CFB4;
    background-image: url("../../shared/assets/icons/switch-active-green-icon.svg") !important;
}

.custom-switch input.form-check-input:focus {
    background-image: none;
}

.custom-switch label.form-check-label {
    display: inline-flex;
    margin-right: auto;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    width: calc(100% - 72px);
    cursor: pointer;
}

.custom-switch .form-check-input:active {
    filter: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.custom-switch .form-check-input:disabled ~ .form-check-label, .custom-switch .form-check-input[disabled] ~ .form-check-label {
    opacity: 1;
}

.StripeElementContainer {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
    background: #E5E7E8;
    border: 0;
    border-radius: 12px;
    padding: 0;
    margin-bottom: 10px;
    height: 56px !important;
    transition: all 0.2s ease-out;
}

.StripeElementContainer > .StripeElement.form-control {
    opacity: 0;
    border: 2px solid #E5E7E8;
    border-radius: 12px;
    padding: 19px 20px;
    height: 100%;
}

.StripeElementContainer > .StripeElement.form-control + label {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #80898A;
    width: 100%;
    padding-left: 20px;
    position: absolute;
    top: 18px;
    transition: all 0.2s ease-out;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--focus,
.StripeElementContainer > .StripeElement.form-control.StripeElement--complete,
.StripeElementContainer > .StripeElement.form-control.StripeElement--invalid {
    opacity: 1;
    padding: 22px 18px 10px 18px;
    border: 2px solid #E5E7E8 !important;
    outline: none !important;
    box-shadow: none !important;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--complete {
    background: #E5E7E8;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--focus {
    background: #FFFFFF;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--invalid {
    background: #E25950;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--focus + label,
.StripeElementContainer > .StripeElement.form-control.StripeElement--complete + label,
.StripeElementContainer > .StripeElement.form-control.StripeElement--invalid + label {
    display: initial;
    font-size: 14px;
    line-height: 17px;
    top: 10px;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--focus + label {
    color: #FF6A39 !important;
}

.StripeElementContainer > .StripeElement.form-control.StripeElement--invalid + label {
    color: #FFFFFF !important;
}

footer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    background-color: #FFFFFF;
    margin-top: 40px !important;
}

footer.consultation-flow-footer {
    background: none;
    padding: 20px 0;
}

footer.consultation-flow-footer .footer-bottom {
    border-top: 0;
    padding-top: 0;
    justify-content: center;
}

footer.consultation-flow-footer .footer-bottom .copyright {
    margin-right: 0;
}

footer .navigation {
    display: flex;
    margin-bottom: 40px;
}

footer .navigation .title {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #001315;
    margin-bottom: 10px;
}

footer .navigation .column {
    margin-right: 40px;
}

footer .navigation .column-login {
    margin-left: auto;
}

footer .navigation .column-login a {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    padding: 4px 14px;
    min-height: 36px;
}

footer .navigation .column-login a svg {
    margin-right: 10px;
}

footer .navigation ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

footer .navigation ul li {
    position: relative;
    cursor: pointer;
}

footer .navigation ul li a {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #001315;
    text-decoration: none !important;
}

footer .footer-bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E7E8;
    padding-top: 20px;
}

footer .footer-bottom .copyright {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: #80898A;
    margin-right: auto;
    display: inline-flex;
    align-items: center;
}

footer .footer-bottom .copyright .logo i {
    width: 83px;
    height: 20px;
}

footer .footer-bottom .copyright .logo {
    margin-right: 20px;
}

footer .footer-bottom .social-media {
    display: flex;
}

footer .footer-bottom .social-media a {
    margin: 0 8px;
    display: inline-flex;
}

.mobile-menu-logged-in {
    display: none !important;
}

@media (max-width: 992px) {
    .custom-switch label.form-check-label,
    input.form-control,
    input.form-control + label,
    select.custom-select {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 767px) {
    .mobile-menu-logged-in > nav {
        display: none !important;
    }

    .mobile-menu-logged-in {
        display: block !important;
    }

    .bm-burger-button.mobile-menu {
        display: block;
    }

    header.menu.landing {
        position: fixed;
        z-index: 11;
        width: 100%;
        left: 0;
    }

    header.landing .view {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 18px 0 !important;
    }

    header.landing .logo {
        margin-right: 0;
        margin-left: 10px;
    }

    header.landing .menu {
        display: none;
    }

    header .is-logged-menu .menu {
        display: flex;
        padding: 0;
    }

    header .is-logged-menu .menu .logo {
        margin: 0;
    }

    header .is-logged-menu .menu nav {
        display: none;
    }

    main.landing,
    main.container {
        margin-top: 67px !important;
    }

    footer {
        padding: 40px 0 20px 0;
    }

    footer .navigation {
        flex-direction: column;
        align-items: center;
    }

    footer .navigation .column {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    footer .navigation .column-login {
        margin-left: 0;
    }

    footer .footer-bottom .copyright,
    footer .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    footer .footer-bottom .social-media {
        margin-top: 15px;
    }

    footer .footer-bottom .copyright .logo {
        margin-right: 0;
        margin-bottom: 15px;
    }

    footer .navigation ul {
        display: block;
        text-align: center;
    }

    footer .navigation ul li {
        margin-left: 0;
        margin-bottom: 15px;
    }

    footer .navigation ul li:after {
        display: none;
    }

    .custom-switch label.form-check-label,
    input.form-control,
    input.form-control + label,
    select.custom-select {
        font-size: 18px;
        line-height: 22px;
    }
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}

.patient-app .time-left p {
    margin-bottom: 0;
}

.avatar > .image-placeholder,
.medic-avatar > .image-placeholder {
    font-family: Rota;
    font-size: 0.9em;
    color: #80898A;
    text-transform: uppercase;
    font-weight: bold;
}

.info-message {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 11px 20px;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.info-message svg {
    min-width: 24px;
    margin-right: 20px;
}

.font-weight-bold {
    font-weight: bold;
}

.green-label {
    background-color: #00CFB4;
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    border: none;
}

.green-label img {
    margin-right: 10px;
}

.modal-specialization .specialization-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.modal-specialization .specialization-item .name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.family-members .profile-box {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.family-members .profile-box .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.specializations .specialization-item,
.family-members .profile-item {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.specializations .specialization-item .specialization-name,
.family-members .profile-item .profile-name {
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #001315;
}

.specializations .specialization-item .specialization-details,
.family-members .profile-item .profile-details {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #80898A;
}

span.trans-placeholder {
    font-weight: bold;
    text-transform: lowercase;
}

.medical-records-accordion .accordion-item,
.medical-records-accordion .accordion-item:first-of-type,
.medical-records-accordion .accordion-item:last-of-type {
    border: 0 !important;
    border-radius: 12px;
    background-color: white;
    margin-top: 10px;
}

.medical-records-accordion .accordion-item:first-of-type {
    margin-top: 0;
}

.medical-records-accordion .accordion-header {
    display: flex;
    align-items: center;
    border-radius: 0;
    border-bottom: 0;
    background-color: transparent;
}

.medical-records-accordion .accordion-header .accordion-button {
    background: transparent;
    color: #000000;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    box-shadow: none;
    border: none;
    outline: none;
    border-bottom: 0 solid #F2F3F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medical-records-accordion .accordion-header .accordion-button .counter-badge {
    background: rgba(255, 106, 57, 0.1);
    border-radius: 12px;
    color: #FF6A39;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: auto;
    padding: 5px 8px;
}

.medical-records-accordion .accordion-header .accordion-button:not(.collapsed) {
    border-bottom: 1px solid #F2F3F3;
}

.medical-records-accordion .accordion-header .accordion-button::after {
    background-image: url("shared/assets/icons/accordion-item-closed.svg");
    height: 10px;
    margin-left: 15px;
}

.medical-records-accordion .accordion-body {
    padding: 0;
}


/* END Phone Number with Prefix*/

.mb-20 {
    margin-bottom: 20px;
}

.container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 900px; /* Increased width */
    width: 90%; /* Take up 90% of the viewport width */
    margin: 20px auto;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
}

.indicator,
.indicatorGreen,
.settings {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.indicator {
    background-color: red;
}

.settings {
    background-color: grey;
}

.indicatorGreen {
    background-color: green;
}

.orderForm {
    /*margin-top: 20px;*/
    font-size: 45px;
    padding: 20px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
}

.dropdown {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 45px;
}

.quantitySelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.quantityButton {
    border: none;
    border-radius: 4px;
    /*padding: 10px;*/
    margin: 10px;
    /*height: 200px;*/
    font-size: 40px;
    /*width: 23%;*/
    width: 23%;
    height: 240px;
    background-color: #e1e1e1;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quantityButton:hover {
    background-color: #d1d1d1;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}


.listDisplay {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.formButton {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s;
}


.maxButton,
.placeOrderButton {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.maxButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
}

.maxButton:hover {
    background-color: #45a049;
}

.formButton {
    background-color: #2196f3;
    color: white;
    padding: 15px 30px;
    width: 100%;
    font-size: 80px;
    margin-top: 80px;
}

.formButton:hover {
    background-color: #1e87e5;
}

.placeOrderButton {
    background-color: #2196f3;
    color: white;
    padding: 15px 30px;
    width: 100%;
    font-size: 80px;
    margin-top: 80px;
}

.placeOrderButton:hover {
    background-color: #1e87e5;
}

@media (max-width: 900px) {
    .container {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .container {
        margin: 10px;
    }

    .quantityButton {
        width: 28%;
    }
}
.quantityButton.active {
    background-color: #4caf50;
}

.quantityButton.active:hover {
    background-color: #45a049;
}

.title {
    font-weight: bold;
    font-size: 45px; /* Increased font size */
    margin-bottom: 20px; /* Increased margin */
    text-align: left; /* Center the text */
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    width: 300px;
    text-align: center;
    width: 500px;
}

.modalContent button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #2196f3;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modalContent button:hover {
    background-color: #1e87e5;
}

.finalLoad {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center the items */
}

.finalLoadTitle {
    font-weight: bold;
    background-color: #f8f8f8;
    padding: 10px;
    text-align: center;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #e0e0e0;
}

.finalLoadValue {
    font-size: 90px;
    font-weight: bold;
    text-align: center;
}

.errorAlertButton{
    font-family: Rota;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 21px;
    color: #FFFFFF !important;
    text-decoration: none !important;
    background: #2196f3;
    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 30px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 5px 0 5px;
}

.finalLoadItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px); /* Assuming there are 2 items, adjust accordingly */
}

.finalLoadTitle {
    font-weight: bold;
    background-color: #f8f8f8;
    padding: 10px 0;
}

.finalLoadButton {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.finalLoadButtonItem {
    color: white;
    background-color: green;
    padding: 10px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: background-color 0.3s ease;
}
.finalLoadButtonItem:hover {
    background-color: darkgreen;
}

.logoContainer {
    alignItems: 'center';
    marginBottom: 50;
}

.logo {
    width: 300px;
    height: 80px;
    resizeMode: 'contain',
}
