ul.pagination {
    margin: 0 -5px;
    justify-content: center;
    border: 0!important;
    border-radius: 0!important;
}
ul.pagination button {
    background: #FFFFFF;
    border: 1px solid #E5E7E8;
    color: #4EDCCA;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 10px;
    margin: 0 5px;
}
ul.pagination button.normal {
    color: #FFFFFF;
    background: #4EDCCA;
    border: 1px solid #4EDCCA;
}
ul.pagination button.disabled {
    background: #BFC4C5;
    border: 1px solid #BFC4C5;
    color: #FFF;
}
@media (max-width: 767px){
    ul.pagination button {
        font-size: 12px;
        line-height: 14px;
        padding: 8px;
        margin: 0 4px;
    }
}