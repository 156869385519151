.gate-page .banner {
    padding: 95px 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
}
.gate-page .banner:after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 240px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);
}
.gate-page .banner .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 40px 30px 30px 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 5;
}
.gate-page .banner .box h1 {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 10px;
}
.gate-page .banner .box p {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-bottom: 30px;
}
.gate-page .banner .box a,
.gate-page .banner .box div.cursor-pointer {
    font-size: 21px;
    line-height: 26px;
    text-decoration: none!important;
    color: #FFFFFF!important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}
.gate-page .banner .box a svg,
.gate-page .banner .box div.cursor-pointer svg {
    margin-right: 18px;
}

.gate-page .download-app {
    position: relative;
    z-index: 5;
}
.gate-page .download-app .text {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.gate-page .download-app .apps-button {
    display: flex;
    justify-content: center;
}
.gate-page .download-app .apps-button a {
    margin: 0 7px;
}
.gate-page .download-app .apps-button .app-store-icon {
    background-image: url("../../shared/assets/icons/app-store-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 117px;
    height: 40px;
    display: inline-flex;
    cursor: pointer;
}
.gate-page .download-app .apps-button .google-play-icon {
    background-image: url("../../shared/assets/icons/google-play-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 130px;
    height: 40px;
    display: inline-flex;
    cursor: pointer;
}

section.specialists {
    margin-top: 55px;
    margin-bottom: 60px;
}
section.specialists h2 {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}
section.specialists .navigation-carousel {
    margin-left: auto;
    display: flex;
}
section.specialists .navigation-carousel > div {
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}
section.specialists .colp10 {
    margin-bottom: 20px;
}
section.specialists .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
section.specialists .box .profile-picture {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

section.specialists .box .profile-picture .avatar {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px solid rgba(0, 19, 21, 0.1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.specialists .box .rating {
    font-family: Rota, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #80898A;
    margin-bottom: 8px;
    display: flex;
}
section.specialists .box .rating svg {
    margin-right: 5px;
}
section.specialists .box .fullName {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #001315;
}
section.specialists .box .specialization {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #001315;
}
section.specialists .box .clinic {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #80898A;
}

section.specializations {
    margin-bottom: 60px;
}
section.specializations h2 {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    color: #001315;
    margin-bottom: 25px;
}

section.specializations > .row {
    justify-content: center;
}
section.specializations .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 15px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
section.specializations .box .title {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #001315;
    margin-top: 13px;
}
section.specializations .view-all-specializations {
    display: flex;
    justify-content: center;
}
section.specializations .white-button {
    color: #FF6A39;
    display: inline-flex;
    align-items: center;
    padding: 9px 10px 9px 20px;
    min-height: 46px;
    max-width: 400px;
    margin: auto;
    justify-content: center;
}
section.specializations .white-button svg {
    margin-right: 0;
}

i.main-screen-specialization-icon {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px
}
